<template>
    <b-overlay :opacity="overlay.opacity" :show="overlay.visible" rounded="sm">
        <b-container class="mt-3" fluid>
            <!-- title toolbar -->
            <TitleToolbar :bus="titleToolbar.bus"
                          :options="titleToolbar.options"
                          :response.sync="titleToolbar.response"
                          :settings="titleToolbar.settings"
            ></TitleToolbar>
            <!-- table -->
            <b-table v-if="table.fields.length > 0"
                     id="main-table"
                     :current-page="titleToolbar.options.pagination.currentPage" :fields="table.fields"
                     :filter="titleToolbar.response.filter.search"
                     :filter-function="null"
                     :filterIncludedFields="titleToolbar.response.filter.columnOn"
                     :items="table.items"

                     :per-page="titleToolbar.options.pagination.perPage"
                     :sort-by.sync="table.sortBy"
                     :sort-desc.sync="table.sortDesc"

                     head-variant="dark"
                     hover

                     outlined
                     responsive="sm"

                     sort-icon-left
                     striped
                     table-variant="secondary"
                     @filtered="onFiltered"
            >
                <!-- eu_member -->
                <template v-slot:cell(eu_member)="row">
                    <div class="d-flex justify-content-center align-items-center" style="width: 60px;">
                        <b-icon-check
                            v-if="row.item.eu_member"
                            scale="1.5"
                            variant="success"
                        ></b-icon-check>
                    </div>
                </template>
                <!-- active -->
                <template v-slot:cell(active)="row">
                    <div class="d-flex justify-content-center align-items-center" style="width: 60px;">
                        <b-icon-sun
                            v-if="row.item.active"
                            scale="1.2"
                            variant="success"
                        ></b-icon-sun>
                        <b-icon-sun
                            v-else
                            scale="1.2"
                            variant="danger"
                        ></b-icon-sun>
                    </div>
                </template>
                <!-- actions -->
                <template v-slot:cell(action)="row">
                    <div class="d-flex justify-content-around">
                        <!-- detail -->
                        <span v-if="row.detailsShowing">
                            <b-icon-chevron-double-up
                                v-b-tooltip.hover
                                :title="$t('form.detail_hide')"
                                scale="1.2"
                                style="cursor: pointer; margin-right: 3px;"
                                variant="info"
                                @click="row.toggleDetails"
                            ></b-icon-chevron-double-up>
                        </span>
                        <span v-else>
                            <b-icon-chevron-double-down
                                v-b-tooltip.hover
                                :title="$t('form.detail_show')"
                                scale="1.2"
                                style="cursor: pointer; margin-right: 3px;"
                                variant="info"
                                @click="row.toggleDetails"
                            ></b-icon-chevron-double-down>
                        </span>
                        <!-- edit -->
                        <b-icon-file-text
                            v-b-tooltip.hover
                            :title="$t('form.edit')"
                            scale="1.2"
                            style="cursor: pointer; margin-right: 3px;"
                            variant="info"
                            @click="recordEdit(row.item)"
                        ></b-icon-file-text>
                        <!-- delete -->
                        <b-icon-trash
                            v-b-tooltip.hover
                            :title="$t('form.delete')"
                            scale="1.2"
                            style="cursor: pointer;"
                            variant="danger"
                            @click="recordDelete(row.item)"
                        ></b-icon-trash>
                    </div>
                </template>
                <!-- row details -->
                <template v-slot:row-details="row">
                    <TableDetail :columns="columns" :data="getTableDetailData(row.item.id)"></TableDetail>
                </template>
            </b-table>
            <!-- pagination -->
            <b-pagination
                v-model="titleToolbar.options.pagination.currentPage"
                :per-page="titleToolbar.options.pagination.perPage"
                :total-rows="this.titleToolbar.options.filterCount"
                aria-controls="main-table"
                limit="20"
                size="sm"
            ></b-pagination>
            <!-- modal form -->
            <b-modal
                id="modalForm"
                :cancel-title="$t('form.cancel')"
                :ok-title="$t('form.save')"
                :title="modal.title"
                footerBgVariant="light"
                headerBgVariant="light"
                scrollable
                size="xl"
                @cancel="modalFormCancel"
                @hide="modalFormHide"
                @ok="modalFormOk"
            >
                <FormState
                    :data="formData"
                    :form.sync="form"
                    :modalBus="modal.bus"
                ></FormState>
            </b-modal>
        </b-container>
    </b-overlay>
</template>

<script>
import Vue from 'vue';
import TitleToolbar from "@/components/general/elements/TitleToolbar_deprecated";

import FormState from "./FormState";
import TableDetail from "../../general/elements/TableDetail_deprecated";
import utilsGeneral from "../../../utils/utilsGeneral";
// @group Form.REGISTER
export default {
    name: 'States',
    components: {TableDetail, TitleToolbar, FormState},
    props: {},
    data() {
        return {
            pathMain: `api/v1/register/states`,
            path1: `api/v1/register/state`,
            form: {
                valid: null,
                record: [],
            },
            data: {},
            formData: {},
            columns: [
                {
                    key: 'id',
                    label: 'id',
                    sortable: true,
                    show: true,

                    filter: true,
                    detailShowRow: true
                },
                {
                    key: 'name',
                    label: this.$t('form.name'),
                    sortable: true,
                    show: true,

                    filter: true,
                    detailShowRow: true
                },
                {
                    key: 'full_name',
                    label: this.$t('form.name_full'),
                    sortable: true,
                    show: false,

                    filter: true,
                    detailShowRow: true
                },
                {
                    key: 'code',
                    label: this.$t('register.code'),
                    sortable: true,
                    show: true,

                    filter: true,
                    detailShowRow: true
                },
                {
                    key: 'mark_2',
                    label: this.$t('register.mark_2'),
                    sortable: true,
                    show: true,

                    filter: true,
                    detailShowRow: true
                },
                {
                    key: 'mark_3',
                    label: this.$t('register.mark_3'),
                    sortable: true,
                    show: true,

                    filter: true,
                    detailShowRow: true
                },
                {
                    key: 'domain',
                    label: this.$t('register.domain'),
                    sortable: true,
                    show: true,

                    filter: true,
                    detailShowRow: true
                },
                {
                    key: 'eu_member',
                    label: this.$t('register.eu_member'),
                    sortable: true,
                    show: true,

                    filter: true,
                    detailShowRow: true
                },

                {
                    key: 'active',
                    label: this.$t('form.active'),
                    thStyle: {'width': '60px'},
                    sortable: true,
                    show: true,

                    filter: true,
                },
            ],
            overlay: {
                visible: false,
                opacity: 0.7,
            },
            titleToolbar: {
                settings: {
                    title: this.$tc('register.state', 2),
                    filter: {
                        scope: [],
                    }
                },
                options: {
                    rowsCount: 0,
                    filterCount: 0,
                    pagination: {
                        perPage: 10,
                        currentPage: 1,
                    },
                },
                response: {
                    filter: {
                        search: '',
                        columnOn: []
                    }
                },
                bus: new Vue(),
            },
            modal: {
                titleDefault: this.$tc('register.state', 1),
                title: '',
                bus: new Vue(),
            },
            table: {
                items: [],
                fields: [],
                sortBy: 'id',
                sortDesc: false,
            },
        }
    },
    mounted() {
        this.titleToolbar.bus.$on('baseSelect', this.baseSelect);
        this.titleToolbar.bus.$on('recordNew', this.recordNew);

        this.baseSelect();
    },
    methods: {
        showOverlay(show) {
            this.overlay.visible = show;
        },
        // <-- table data -->
        setTableData() {
            this.setTableItems();
            this.setTableFieldsData();

            this.$set(this.titleToolbar.response, 'columns', this.columns);

            this.setTableFilterData();
        },
        setTableItems() {
            // <-- table_items -->
            this.table.items = [];
            let array = [];
            let name = '';
            let name_full = '';

            this.data.forEach((value, index) => {
                name = value.meta.name + this.$t('form.meta.message', {msg: this.$t('form.name').toLowerCase()});
                name_full = value.meta.name_full + this.$t('form.meta.message', {msg: this.$t('form.name_full')});

                if (utilsGeneral.keyExists(value.locale, this.$i18n.locale)) {
                    name = value.locale[this.$i18n.locale].name == null ? name : value.locale[this.$i18n.locale].name;
                    name_full = value.locale[this.$i18n.locale].name_full == null ? name_full : value.locale[this.$i18n.locale].name_full;
                }
                array.push(
                    {
                        id: value.id,
                        active: value.active,
                        name: name,
                        full_name: name_full,

                        code: value.content.code,
                        domain: value.content.domain,
                        mark_2: value.content.mark_2,
                        mark_3: value.content.mark_3,
                        eu_member: value.content.eu_member,

                        // _rowVariant: value.active ? '' : 'warning',
                        // _showDetails: true
                    }
                );
            });
            this.table.items = array;
            this.titleToolbar.options.rowsCount = this.table.items.length;
            this.titleToolbar.options.filterCount = this.titleToolbar.options.rowsCount;
        },
        setTableFieldsData() {
            let array = [];
            this.columns.forEach((column, index) => {
                if (column.show) {
                    array.push(
                        {
                            key: column.key,
                            label: column.label,
                            sortable: column.sortable,
                            // <-- additionally -->
                            filter: column.filter,
                        }
                    );
                }
            });
            // <-- action column -->
            array.push(
                {key: 'action', label: '', thStyle: {width: '85px'}},
            );
            // <--  -->
            this.$set(this.table, 'fields', array);
        },
        setTableFilterData() {
            this.titleToolbar.settings.filter.scope = [];

            let array = [];
            this.table.fields.forEach((value, index) => {
                if (value.filter) {
                    array.push(
                        {
                            key: value.key,
                            label: value.label,
                        }
                    );
                }
            });
            this.titleToolbar.settings.filter.scope = array;
        },
        getTableDetailData(id) {
            let data = [];
            this.data.find((n) => {
                if (n.id === id) {
                    data = n;
                }
            })
            return data;
        },
        // <-- filter -->
        onFiltered(filteredItems) {
            this.titleToolbar.options.filterCount = filteredItems.length;
            this.titleToolbar.options.pagination.currentPage = 1;
        },
        // <-- modalForm -->
        modalFormHide() {
            // this.record = {};
        },
        modalFormCancel() {
            // this.record = {};
        },
        modalFormOk(bvModalEvent) {
            // <-- prevent default to close modal -->
            bvModalEvent.preventDefault();
            // <-- check form validity from modal -->
            this.modal.bus.$emit('submit');
            if (!this.form.valid) {
                return;
            }
            // <-- if form valid save record -->
            if (this.form.record.id > 0) {
                this.baseUpdate();
                return;
            }
            this.baseInsert();
        },
        hideModal() {
            // <-- hide the modal manually -->
            this.$nextTick(() => {
                this.$bvModal.hide('modalForm')
            });
        },
        // <-- records -->
        recordNew() {
            // <-- axios -->
            this.axios.get(this.path1 + `/getForeignData`, this.__config)
                .then((response) => {
                    this.$set(this.modal, 'title', this.$t('form.add') + ': ' + this.modal.titleDefault.toUpperCase());
                    // <-- form -->
                    this.$set(this.form, 'valid', false);
                    this.$set(this.form, 'record', null);
                    // <-- data -->
                    this.$set(this, 'formData', response.data);
                    // <--  -->
                    this.showOverlay(false);
                    this.$bvModal.show('modalForm');
                })
                .catch((error) => {
                    // on error executed
                })
                .finally(function () {
                    // always executed
                });
        },
        recordEdit(row) {
            this.$set(this.modal, 'title', this.$t('form.edit') + ': ' + this.modal.titleDefault.toUpperCase());
            this.baseSelectRecord(row.id);
        },
        recordDelete(row) {
            let message = utilsGeneral.capitalize(this.modal.titleDefault) + ' id: ' + row.id + ' ' + this.$t('form.be_deleted') + '.';
            this.$bvModal.msgBoxConfirm(message, {
                title: this.$t('form.please_confirm'),
                // size: 'sm',
                // buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: this.$t('form.delete'),
                cancelTitle: this.$t('form.cancel'),
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
                .then(value => {
                    if (value) {
                        this.baseDelete(row.id);
                    }
                })
                .catch(err => {
                    // on error executed
                })
        },
        // <-- api calls -->
        baseSelect() {
            this.showOverlay(true);
            // <-- axios -->
            this.axios.get(this.pathMain, this.__config)
                .then((response) => {
                    this.data = response.data;
                    this.setTableData();
                    this.showOverlay(false);
                })
                .catch((error) => {
                    // on error executed
                })
                .finally(function () {
                    // always executed
                });
        },
        baseSelectRecord(id) {
            this.showOverlay(true);
            // <-- axios -->
            this.axios.get(this.pathMain + `/${id}`, this.__config)
                .then((response) => {
                    // <-- form -->
                    this.$set(this.form, 'valid', false);
                    this.$set(this.form, 'record', response.data.record);
                    // <-- data -->
                    this.$set(this, 'formData', response.data.data);
                    // <--  -->
                    this.$bvModal.show('modalForm');
                    this.showOverlay(false);
                })
                .catch((error) => {
                    // console.log("error:", error);
                })
                .finally(function () {
                    // always executed
                });
        },
        baseInsert() {
            this.showOverlay(true);
            // <-- data -->
            let record = this.form.record;
            let data = {
                'record': record,
            };
            // <-- axios -->
            this.axios.post(this.pathMain, data, this.__config)
                .then((response) => {
                    this.data = response.data;
                    this.setTableData();
                    this.hideModal();
                    this.showOverlay(false);
                })
                .catch((error) => {
                    // console.log("error:", error);
                })
                .finally(function () {
                    // always executed
                });
        },
        baseUpdate() {
            this.showOverlay(true);
            // <-- data -->
            let record = this.form.record;
            let data = {
                'record': record,
            };
            // <-- axios -->
            this.axios.put(this.pathMain + `/${record.id}`, data, this.__config)
                .then((response) => {
                    this.data = response.data;
                    this.setTableData();
                    this.hideModal();
                    this.showOverlay(false);
                }).catch((error) => {
                if (error.response) {
                }
            });
        },
        baseDelete(record_id) {
            this.showOverlay(true);
            // <-- axios -->
            this.axios.delete(this.pathMain + `/${record_id}`, this.__config)
                .then((response) => {
                    this.data = response.data;
                    this.setTableData();
                    this.showOverlay(false);
                })
                .catch((error) => {
                    // console.log("error:", error);
                })
                .finally(function () {
                    // always executed
                });
        },
    },
    computed: {},
    filters: {},
    watch: {
        'titleToolbar.response.columns': {
            handler: function (value, oldValue) {
                this.$set(this, 'columns', value);
                this.setTableFieldsData();
            },
            deep: true
        },
    }
}
</script>

<style scoped>

</style>
